import usePrefix from '@/hooks/usePrefix';
import Link from 'next/link';
import React from 'react';
import { ReactElement } from 'react';
import { IAirport } from '../types';

export interface IAirportsList {
  airports: { result: IAirport[] };
  grid?: number;
  gap?: number;
}
function AirportsList({
  airports,
  grid = 4,
  gap = 2,
}: IAirportsList): ReactElement {
  const prefix = usePrefix();
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-${grid} gap-y-2 gap-x-${gap} place-items-start`}
    >
      {airports?.result?.map((airport: IAirport) => (
        <Link
          href={`${prefix}/parking/${airport.airport_frienldy_url}`}
          key={airport.airport_id}
          className="text-primary hover:underline hover:text-primary"
          title={airport.airport_name}
        >
          »{airport.airport_name}
        </Link>
      ))}
    </div>
  );
}

export default AirportsList;
