import AirportsList from '@/components/AirportsList';
import { cacheFetch } from '@/helpers/cacheFetch';
import React from 'react';

export const getStaticProps = async (context) => {
  let uri =
    process.env.NODE_ENV === 'production'
      ? 'https://onairparking.com/api/Airport'
      : 'http://localhost/api/Airport';
  if (process.env.FORCE_API_ADDRESS) {
    uri = `${process.env.FORCE_API_ADDRESS}/api/Airport`;
  }
  const airports = await cacheFetch(uri);
  return { props: { airports } };
};

export default function Custom404({ airports }) {
  return (
    <div className="h-full container mx-auto px-4 my-12 flex flex-col justify-center items-center ">
      <h2 className="text-9xl">Oops!</h2>
      <p className="mt-4 text-base">
        Sorry, the page you visited does not exist
      </p>
      <p className="text-base">but you can still see our airports.</p>
      {airports && (
        <div className="my-12">
          <AirportsList airports={airports} grid={3} gap={12} />
        </div>
      )}
    </div>
  );
}
